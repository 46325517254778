<template>
    <div class="container1">
        <div class="row">
            <div class="info col-md-6 mt-5">
                <h1>Tantra voor koppels</h1>
                <p>Misschien vinden jullie het fijn om als koppel samen gemasseerd te worden. Dan bieden wij jullie een duo
                    tantra massage aan. Deze massage gaat door in 1 ruimte waarbij John de vrouw zal masseren en Lin de man.

                </p>
                <router-link to="/aanbod" class="terug btn">Ga terug</router-link>

            </div>

        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="black" fill-opacity="1"
            d="M0,224L48,224C96,224,192,224,288,202.7C384,181,480,139,576,138.7C672,139,768,181,864,197.3C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
        </path>
    </svg>
    <div class="container2">
        <div class="row ">
            <div class="info col-md-5">
                <h1>Handelingen</h1>
                <p>Ook hier maken we voorafgaand aan de massage tijd om bij een kopje thee kennis te maken en praten we over
                    wat jullie noden zijn. Daarna kunnen jullie je douchen, een goede hygiëne is tenslotte heel belangrijk.
                    Zowel een lekker-geurende douchegel, badhanddoeken als badjassen zijn voorzien. Na het
                    verbindingsritueel gaan we over tot de massage. Zowel de gever als de ontvanger zijn hierbij naakt. Na
                    de massage maken we nog even tijd om wat na te praten als daar nood aan is, waarna jullie indien jullie
                    dan wensen terug kunnen douchen.</p>

            </div>
            <div class="prijzen col-md-6">
                <h1>Prijzen</h1>
                <div class="massage-item">
                    <div class="massage-name">De sensuele tantra massage</div>
                    <div class="massage-duration">90 min</div>
                    <div class="massage-price">350 euro</div>
                </div>
                <div class="massage-item">
                    <div class="massage-name">Yoni en Lingam massage <br> (altijd incl. sensuele massage)</div>
                    <div class="massage-duration">120 min</div>
                    <div class="massage-price">400 euro</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'TantraKoppelsView'
}


</script>

<style scoped>
*, ::after, ::before {
  box-sizing: border-box;
  overflow-x: hidden;
}
.container1 {
    padding-top: 12rem;
    background-image: url('../assets/koppel 2 banner.png');
    background-size: cover;
}

.info {
    text-align: left;
    padding-left: 12rem;
    margin-bottom: 16rem;
}

.info p {
    font-size: 1.5rem;
    color: white;
    overflow-y: hidden;

}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
}

p,
.prijzen div {
    font-family: 'Cormorant', serif;

}


h1 {
    text-align: left;
    font-size: 3rem;
    color: #ecd9a6;
    overflow-x: unset;

}

.image {
    padding-top: 3rem;

}

.image img {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 70%;
}



.container2 h1 {
    font-size: 4rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: var(--bs-heading-color);

}

.container2 p {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #2c3e50;
}



.prijzen {

    text-align: left;
    padding-left: 12rem;

}

.prijzen div {
    font-size: 1.5rem;
}

.massage-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.massage-name,
.massage-duration,
.massage-price {
    text-align: center;
    overflow-y: hidden;
}

.massage-name {
    text-align: left;
    width: 444px !important;
}

.massage-price {
    text-align: right;
}

.terug {
    font-size: 1.5rem;
    color: black;
    text-decoration: none;
    font-family: 'Cormorant', serif;
    background-color: #CBA135;

}

.terug:hover {
    color: white;
    background-color: black;
}





    
@media screen and (max-width: 768px) {
    .info {
        padding-left: 0;
        padding: 2rem;
        
    }
    
        

    .info h1, .prijzen h1, .yonim h1{
        margin: 0;
        padding: 0;
        font-size: 3rem;
    }

    .prijzen {
        padding-left: 0;
        padding: 2rem;
    }

    .yonim h1 {
        margin-left: 0;
        
    }

    .prijzen div {
        font-size: 1.2rem;
    }

    .info {
        margin-bottom: 1rem;
    }

    
    
}


</style>