<template>
    <div class="container1">
        <div class="row">
            <div class="info col-md-6 mt-5">
                <h1>Tantra voor vrouwen</h1>
                <p>"Sensuele tantra biedt vrouwen een pad naar zelfontdekking en empowerment, waarbij ze hun sensuele
                    essentie
                    omarmen
                    en in harmonie brengen met hun innerlijke kracht. Door ademhaling, aanraking en meditatie leren vrouwen
                    hun
                    seksuele
                    energie te kanaliseren voor zelfgenezing en groei. Sensuele tantra viert de schoonheid van het
                    vrouwelijk
                    lichaam en
                    moedigt vrouwen aan om zichzelf te eren en te respecteren zoals ze zijn. Het nodigt vrouwen uit om te
                    vertrouwen
                    op
                    hun intuïtie en innerlijke wijsheid bij het navigeren door hun sensuele reis. Sensuele tantra biedt een
                    diepere
                    verbinding, waarbij zelfliefde en zelfzorg centraal staan."</p>
                <router-link to="/aanbod" class="terug btn">Ga terug</router-link>
            </div>

        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="black" fill-opacity="1"
            d="M0,224L48,224C96,224,192,224,288,202.7C384,181,480,139,576,138.7C672,139,768,181,864,197.3C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
        </path>
    </svg>
    <div class="container2">
        <div class="row ">
            <div class="info col-md-5">
                <h1>Handelingen</h1>
                <p>Deze massage wordt gegeven door Lin of John. Bij aankomst wordt je een kopje thee aangeboden, maken we
                    kennis en
                    praten we over wat jouw noden zijn. Daarna kan je je douchen, een goede hygiëne is tenslotte heel
                    belangrijk.
                    Zowel een lekker-geurende douchegel, badhanddoeken als badjas zijn voorzien. Na het verbindingsritueel
                    gaan we
                    over tot de massage. Zowel de gever als de ontvanger zijn hierbij naakt. Na de massage maken we nog even
                    tijd om
                    wat na te praten als daar nood aan is, waarna je indien gewenst terug kan douchen.</p>

            </div>
            <div class="prijzen col-md-6">
                <h1>Prijzen</h1>
                <div class="massage-item">
                    <div class="massage-name">De sensuele tantra massage</div>
                    <div class="massage-duration">90 min</div>
                    <div class="massage-price">200 euro</div>
                </div>
                <div class="massage-item">
                    <div class="massage-name">Yoni massage (altijd incl. sensuele massage)</div>
                    <div class="massage-duration">120 min</div>
                    <div class="massage-price">250 euro</div>
                </div>
            </div>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#ffffff" fill-opacity="1" d="M0,224L48,213.3C96,203,192,181,288,149.3C384,117,480,75,576,90.7C672,107,768,181,864,197.3C960,213,1056,171,1152,160C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
</svg>
    <div class="yonim">
        <div class="container2">
            <div class="yoni row ">
                <h1>Yoni Massage</h1>
                <div class="info col-md-5">
                    
                    <h2>Wat is Yoni massage?</h2>
                    <p>Deze massage wordt gegeven door Lin of John. Bij aankomst wordt je een kopje thee aangeboden, maken
                        we
                        kennis en
                        praten we over wat jouw noden zijn. Daarna kan je je douchen, een goede hygiëne is tenslotte heel
                        belangrijk.
                        Zowel een lekker-geurende douchegel, badhanddoeken als badjas zijn voorzien. Na het
                        verbindingsritueel
                        gaan we
                        over tot de massage. Zowel de gever als de ontvanger zijn hierbij naakt. Na de massage maken we nog
                        even
                        tijd om
                        wat na te praten als daar nood aan is, waarna je indien gewenst terug kan douchen.</p>

                </div>
                <div class="info col-md-5">
                    <h2>Verloop</h2>
                    <p>Ook deze kan gegeven worden door zowel Lin als door John. Tijdens het gesprek in het begin van de
                        sessie
                        zal er besproken worden wat de grenzen zijn van zowel de ontvanger als van de gever.

                        Een yoni massage zal altijd vooraf gegaan worden door de sensuele massage waardoor er al volledige
                        ontspanning is van het lichaam en de geest en waarbij er reeds een vertrouwen is tussen de gever en
                        de
                        ontvanger. Tijdens de sensuele massage zal er een borstmassage gegeven worden, deze is een
                        belangrijke
                        voorbereiding op de aanraking met de yoni omdat deze energetisch verbonden zijn. Het aanraken en
                        gebruik
                        maken van bepaalde drukpunten bij de externe yoni massage zal zorgen voor de activatie van de
                        energie
                        zodat daarna kan worden overgegaan tot de interne yoni massage. Er zal steeds toestemming gevraagd
                        worden of er mag overgegaan worden tot de interne yoni massage. Tijdens de interne yoni massage
                        wordt er
                        aandacht gegeven aan alle weefsel binnen de yoni en zal er ook op zoek gegaan worden naar de G-spot.
                        Het
                        wakker maken van energie via de yoni massage met de juiste aanrakingen kan zorgen voor een veelheid
                        aan
                        positieve effecten. Deze vorm van aanraking kan diepgaand en helend zijn. Het resultaat is dat er
                        veel
                        meer bewustzijn in het hele bekkengebied komt en dat de yoni ontvankelijker wordt en meer gaat
                        stromen.

                        Er kunnen tijdens dit proces veel emoties vrijkomen. Daarom doe je dit als vrouw als je voelt dat je
                        er
                        aan toe bent. Als je tijdens het proces merkt dat het teveel voor je wordt, of de gever merkt dat
                        het
                        teveel wordt dan zal de sessie beëindigd worden. </p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TantraVrouwenView'
}


</script>

<style scoped>

*, ::after, ::before {
  box-sizing: border-box;
  overflow-x: hidden;
}


.container1 {
    padding-top: 12rem;
    background-image: url('../assets/vrouw 2 banner.png');
    background-size: cover;
}

.info {
    text-align: left;
    padding-left: 12rem;
}

.info p {
    font-size: 1.5rem;
    color: white;
    overflow-y: hidden;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
    overflow-x: unset;
}

p,
.prijzen div {
    font-family: 'Cormorant', serif;

}

.yoni h1 {
    margin-left: 11rem;
    margin-top: 4rem;
}


h1 {
    text-align: left;
    font-size: 3rem;
    color: #ecd9a6;

}

.image {
    padding-top: 3rem;

}

.image img {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 70%;
}



.container2 h1 {
    font-size: 4rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: var(--bs-heading-color);

}

.container2 p {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #2c3e50;
}



.prijzen {

    text-align: left;
    padding-left: 12rem;

}

.prijzen div {
    font-size: 1.5rem;
}

.massage-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.massage-name,
.massage-duration,
.massage-price {
    text-align: center;
}

.massage-name {
    text-align: left;
    width: 444px !important;
}

.massage-price {
    text-align: right;
}

.terug {
    font-size: 1.5rem;
    color: black;
    text-decoration: none;
    font-family: 'Cormorant', serif;
    background-color: #CBA135;

}

.terug:hover {
    color: white;
    background-color: black;
}

.yonim{

    background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 768px) {
    .info {
        padding-left: 0;
        padding: 2rem;
        margin-top: 0rem !important;
        
    }
    
        

    .info h1, .prijzen h1, .yonim h1{
        margin: 0;
        padding: 0;
        font-size: 3rem;
    }

    .prijzen {
        padding-left: 0;
        padding: 2rem;
    }

    .yonim h1 {
        margin-left: 2rem;
        
    }

    .prijzen div {
        font-size: 1.2rem;
    }

    .info {
        margin-bottom: 1rem;
    }

    
    
}


</style>