import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/Home.vue';
import AanbodView from '../views/Aanbod.vue';
import AlgemeenView from '../views/Algemeen.vue';
import TantraKoppelsView from '../views/TantraKoppels.vue';
import TantraMannenView from '../views/TantraMannen.vue';
import TantraVrouwenView from '../views/TantraVrouwen.vue';
import ContactView from '../views/Contact.vue';
import WatView from '../views/Wat.vue'
import AboutView from '../views/About.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/aanbod',
        name: 'Aanbod',
        component: AanbodView
    },
    {
        path: '/algemeen',
        name: 'Algemeen',
        component: AlgemeenView
    },
    {
        path: '/tantra-koppels',
        name: 'TantraKoppels',
        component: TantraKoppelsView
    },
    {
        path: '/tantra-mannen',
        name: 'TantraMannen',
        component: TantraMannenView
    },
    {
        path: '/tantra-vrouwen',
        name: 'TantraVrouwen',
        component: TantraVrouwenView
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactView
    },
    {
        path: '/wat',
        name: 'Wat',
        component: WatView
    },
    {
        path: '/about',
        name: 'About',
        component: AboutView
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

export default router;
