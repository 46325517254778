<template>
    <h1>Hier komt de info van de gekoze massage</h1>
    <h2>"Nog onder constructie"</h2>

    
    <router-link to="/aanbod" class="btn btn-warning">Ga terug</router-link>


</template>

<script>
export default {
    name: 'AlgemeenView'
}


</script>

<style scoped>

h1 {
    font-family: 'Playfair Display', serif;
    text-align: center;

    font-size: 3rem;
    color: #2c3e50;
    padding-top: 20rem;
}


</style>