<template>
  <div>
    <div v-if="showArrow" ref="arrow" class="scroll-arrow" @click="scrollToGiftCard">
      Vraag hier je cadeaubon aan
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="black" fill-opacity="1"
        d="M0,224L48,224C96,224,192,224,288,202.7C384,181,480,139,576,138.7C672,139,768,181,864,197.3C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
      </path>
    </svg>
    <div class="aanbod container">
      <h1>Aanbod</h1>
      <h2>Dit is ons aanbod met alle prijzen, uitgebreide informatie en meer details. Neem gerust een kijkje om alles te
        ontdekken wat we te bieden hebben.</h2>
      <div class="aanbodrow row">
        <div class="col-md-4">
          <span class="image-container">
            <router-link to="/tantra-vrouwen">
              <img src="../assets/vrouw 1.jpg" class="card-img-top" alt="Massage 1">
              <span class="overlay">Tantra voor vrouwen</span>
            </router-link>
          </span>
        </div>
        <div class="col-md-4">
          <span class="image-container">
            <router-link to="/tantra-mannen">
              <img src="../assets/man3.png" class="card-img-top" alt="Massage 1">
              <span class="overlay">Tantra voor mannen</span>
            </router-link>
          </span>
        </div>
        <div class="col-md-4">
          <span class="image-container">
            <router-link to="/tantra-koppels">
              <img src="../assets/koppel.png" class="card-img-top" alt="Massage 1">
              <span class="overlay">Tantra voor koppels</span>
            </router-link>
          </span>
        </div>
      </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#ffffff" fill-opacity="1"
        d="M0,224L48,213.3C96,203,192,181,288,149.3C384,117,480,75,576,90.7C672,107,768,181,864,197.3C960,213,1056,171,1152,160C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
      </path>
    </svg>

    <div class="cadeau" ref="cadeaubon">
      <h1>Cadeaubon aanvragen</h1>
      <form @submit.prevent="submitForm">
        <div class="row input-container">
          <div class="col-md-6 col-sm-12">
            <div class="styled-input">
              <input v-model="formData.name" required />
              <label>Naam</label>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="styled-input">
              <input v-model="formData.price" required />
              <label>Prijs</label>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="styled-input">
              <input v-model="formData.email" required />
              <label>Email</label>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="styled-input">
              <input v-model="formData.phone" required />
              <label>Telefoon nummer</label>
            </div>
          </div>
          <div class="col-xs-12">
            <button type="submit" class="btn-lrg submit-btn">Vraag aan</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>





<script>
import emailjs from 'emailjs-com';

export default {
  name: 'AanbodView',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        price: ''
      },
      showArrow: true
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await emailjs.send('service_o9malbk', 'template_l1vo91p', this.formData, 'vi-pw5wMVFj3-WFS4');
        if (response.status === 200) {
          console.log('Email successfully sent!');
          alert('Uw cadeaubon van het bedrag  €' +  this.formData.price + ' is aangevraagd. We nemen zo snel mogelijk contact met u op.');
          this.formData = {
            name: '',
            email: '',
            phone: '',
            price: ''
          };
        } else {
          throw new Error('Failed to send email');
        }
      } catch (error) {
        console.error('There was a problem with the email sending operation:', error);
        alert('Failed to send email. Please try again later.');
      }
    },
    scrollToGiftCard() {
      const element = this.$refs.cadeaubon;
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      const element = this.$refs.cadeaubon;
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition >= element.offsetTop) {
        this.showArrow = false;
      } else {
        this.showArrow = true;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>








<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: 'Playfair Display', serif;
}

p {
  font-family: 'Cormorant', serif;
}

.aanbod {
  margin-top: -8rem !important;
}

.aanbod h1,
.cadeau h1 {
  text-align: center;
  display: block;
  font-size: 3rem;
}

.aanbod h2 {
  margin-bottom: 3rem;
  text-align: center;
  display: block;
}

.card {
  transition: transform 0.3s;
  cursor: pointer;
  margin-bottom: 20px;
  /* Adds some space between the cards */
}

.card:hover {
  transform: scale(1.05);
}

.card-img-top {
  width: 100%;
  height: 15vw;
}

.moreInfoBtn {
  color: grey;
  text-decoration: none;
}

.card-link {
  text-decoration: none;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  transition: filter 0.3s ease;
  transition: transform 0.3s;
  border-radius: 9px;
}

.image-container:hover img {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 40px;
  padding: 10px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 0;
}

.cadeau {
  background-color: white;
  padding: 1rem;
}

input:focus~label,
textarea:focus~label,
input:valid~label,
textarea:valid~label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 768px) {
  .styled-input {
    width: 100%;
  }

  .aanbod {
    margin-top: 3rem !important;
  }

  .aanbodrow div {
    padding-top: 3rem !important;
  }

  .card-img-top {
    height: 50vw !important;
  }

  .overlay {
    opacity: 1;
    padding: 10px;
  }

}

.styled-input label {
  color: #999;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #dfdcdc;
  color: black;
  border-radius: 4px;
}

input:focus,
textarea:focus {
  outline: 0;
}

input:focus~span,
textarea:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 15em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}

.submit-btn {
  float: right;
  padding: 7px 35px;
  border-radius: 10px;
  display: inline-block;
  background-color: #CFA435;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.10),
    0 1px 1px 0 rgba(0, 0, 0, 0.09);
}

@media (max-width: 768px) {
  .submit-btn {
    width: 100%;
    float: none;
    text-align: center;
  }
}

input[type=checkbox]+label {
  color: #ccc;
  font-style: italic;
}

input[type=checkbox]:checked+label {
  color: #f00;
  font-style: normal;
}

/* Scroll arrow styling */
.scroll-arrow {
  position: fixed;
  top: 93%;
  right: 30px;
  background-color: #CFA435;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.5s ease;
  opacity: 1; 
}
.scroll-arrow.hidden {
  opacity: 0; 
}

.scroll-arrow:hover {
  opacity: 0.8;
}</style>
