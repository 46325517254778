<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <!-- Logo aan de rechterkant -->
        <div class="d-flex justify-content-between  ">
          <div>
            <router-link to="/" class="navbar-brand">
              <img src="../../assets/4logo-tr.png" alt="logo" class="logo-img">
            </router-link>
          </div>
          <!-- Registreer knop aan de rechterkant -->

        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse  align-items-right" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/wat" class="nav-link">Wat is Tantra?</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/aanbod" class="nav-link">Ons aanbod</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">Over ons</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Contact</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  </div>
</template>
  
<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      currentPage: window.location.pathname
    };
  },
  mounted() {
    this.makeNavBlackWhenScrolling();
    this.closeNavbarAfterClickWhenPhoneFormat();

  },

  methods: {
    makeNavBlackWhenScrolling() {
      window.onscroll = function () {
        //only if page width is larger than 768px
        if (window.innerWidth > 768) {
          if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('.navbar').style.backgroundColor = 'black';
            // Fade out logo and make it smaller smoothly
            document.querySelector('.logo-img').style.transition = 'opacity 0.5s, width 0.5s';
            document.querySelector('.logo-img').style.opacity = '0';
            document.querySelector('.logo-img').style.width = '100px';
            // Remove all padding smoothly
            document.querySelector('.navbar').style.transition = 'padding 0.3s';
            document.querySelector('.navbar').style.padding = '0';
            document.querySelector('.navbar-nav').style.transition = 'padding 0.3s';
            document.querySelector('.navbar-nav').style.padding = '1rem';
          } else {
            document.querySelector('.navbar').style.backgroundColor = 'transparent';
            // Fade in logo and make it normal size smoothly
            document.querySelector('.logo-img').style.transition = 'opacity 0.5s, width 0.5s';
            document.querySelector('.logo-img').style.opacity = '1';
            document.querySelector('.logo-img').style.width = '220px';

          }
        }
      };
    },

    closeNavbarAfterClickWhenPhoneFormat() {
     //when i click on an element on the navbar click de button with class "navbar-toggler" so it closes the navbar
      document.querySelectorAll('.nav-link').forEach(item => {
        item.addEventListener('click', () => {
          document.querySelector('.navbar-toggler').click();
        });
      });
    },
      

    isActiveRoute(route) {
      return this.$route.path === route;
    },


  }
}
</script>
  
<style scoped>
.navbar {
  position: fixed;
  background-color: transparent;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s;
  color: black;
  font-family: 'Cormorant', serif;
  font-size: 1.5rem;
}

.navbar .router-link-exact-active {
  color: #CBA135 !important;


}

.navbar-collapse {
  justify-content: flex-end;

}

.navbar-brand img {
  width: 250px;
}

.navbar-nav {
  padding-bottom: 3rem;
}

.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.1em;
  padding: 0.5em 1em;
}


.navbar .navbar-nav .nav-link {
  position: relative;
  padding-bottom: 5px; /* Zorg ervoor dat er ruimte is voor de onderlijn */
}

.navbar .navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #CBA135;
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar .navbar-nav .nav-link:hover::after {
  width: 100%;
  left: 0;
}


.contact {
  text-align: left !important;
  padding: 5px;
  background-color: #CBA135;
}

.contact p {
  margin-bottom: 0px !important;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .navbar {
    /*background: rgb(0, 0, 0);
      background: linear-gradient(180deg, rgb(0, 0, 0) 72%, rgba(0,212,255,0) 100%);*/

    background-image: url("../../assets/wave.svg");
    background-size: cover;
  }

  .navbar-brand img {
    width: 100px;
    margin: 0.5rem;
    padding-bottom: 0.8rem;
  }

  .navbar-brand {
    margin-right: 0;
    padding: 0 1em;
  }

  

  .container-fluid {
    padding-right: 0;
  }

  .navbar-collapse {
    background-color: black;
  }


}
</style>
  