<template>
  <div class="home">
    <div>
      <div class="banner d-flex justify-content-center align-items-center"></div>

      <img v-if="isWideScreen" src="..\assets\wave3.png" alt="wave" class="wave">
      <img v-else src="..\assets\wave3-1.png" alt="wave" class="wave">

      <div class="intro1 d-flex justify-content-center align-items-left">
        <div class="intro1-section col-md-6">
          <h2>Sensueel genot: Een Ode aan de Zintuigen</h2>
          <p class="text-left">Sensueel genot is als een meeslepende symfonie van zintuiglijke verleidingen. Het begint
          met de tedere streling van de huid, een subtiele aanraking die rillingen van opwinding veroorzaakt. Het
          vervolgt met de bedwelmende geur van bloesems die de lucht vult, een zoete belofte van verrukking. Dan is er
          de smaak van verboden vruchten, zacht en weelderig op de tong dansend. Elk moment, elke sensatie, is een
          viering van het menselijk vermogen om te genieten van de geneugten van het leven.
          <br>
          <br>
          In het rijk van sensueel genot, zijn er alleen de vrije uitdrukking van verlangen en bevrediging. Het is een
          dans van intimiteit en verrukking, waarin elke aanraking een verhaal vertelt van passie en plezier. Het is een
          herinnering aan het belang van het koesteren van onze zintuigen, van het waarderen van de eenvoudige genoegens
          die het leven te bieden heeft.
          <br><br>
          Dus laten we ons overgeven aan de verleiding van sensueel genot, laten we ons laten meeslepen door de
          betovering van elk moment. Want in die betovering ontdekken we de ware essentie van het leven - een eindeloze
          viering van liefde, plezier en verrukking.
        </p>
        </div>
        <div class="col-md-5">
          <img src="..\assets\buddha.png" alt="Card 1" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HomeView',
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isWideScreen() {
      return this.windowWidth > 768;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>

<style scoped>
/* Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

/* Typography */
h2 {
  font-family: 'Playfair Display', serif;
}

p {
  font-family: 'Cormorant', serif;
}

/* Banner Styles */
.banner {
  background-image: url('../assets/Banner2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  color: white;
}

.banner h2 {
  font-size: 6rem;
  font-weight: 700;
}

.banner img {
  width: 40%;
  border-radius: 80px;
  position: absolute;
  border: 3px solid #ffffff;
  top: 12rem;
}

/* Wave Styles */
.wave {
  width: 100%;
}

/* Intro Section */
.intro1 {
  padding: 2px;
  border-radius: 20px;
}

.intro1 h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 4px solid #CBA135;
}

.intro1 p {
  font-size: 1.2rem;
  font-weight: 300;
}

.intro1 img {
  width: 90%;
  max-width: 900px;
  border-radius: 20px;
}

.intro1-section {
  text-align: left;
  padding: 50px;
  margin-right: -2rem;
  border-radius: 20px 0px 0px 20px;
}

/* Button Styles */
button, .moreInfoBtn {
  background-color: #cca236;
  color: #fff;
  font-size: 1.4rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px;
  margin-top: 1em;
}

button:hover, .moreInfoBtn:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #FFD700;
}

/* Gold Line */
.gold-line {
  border: 4px solid #CBA135;
  width: 100px;
  margin: 20px 6rem;
  opacity: 1;
}

/* Media Queries */
@media screen and (max-width: 960px) {
  .banner {
    background-image: url('../assets/Banner2-1.png');
    height: 400px;
  }

  .intro1 {
    display: block;
  }

  .intro1 h2 {
    font-size: 1.5rem;
  }

  .intro1-section {
    margin-right: 0;
    padding-top: 10px !important;
  }
}
</style>

