<template>
    <div class="container1">
        <div class="row">
            <div class="info col-md-6 mt-5">
                <h1>Tantra voor mannen</h1>
                <p>"Sensuele tantra biedt mannen een pad naar zelfontdekking en vervulling, waarbij ze hun sensuele kracht
                    en potentieel omarmen. Door middel van ademhaling, aanraking en meditatie leren mannen hun seksuele
                    energie te beheersen en te kanaliseren. Sensuele tantra moedigt mannen aan om dieper in contact te komen
                    met hun lichaam en emoties, waardoor ze een dieper begrip van zichzelf en hun partners kunnen
                    ontwikkelen. Het biedt een veilige ruimte om mannelijkheid te herdefiniëren, weg van stereotypen en
                    verwachtingen. Sensuele tantra nodigt mannen uit om te streven naar een diepere verbinding en
                    intimiteit, zowel met henzelf als met anderen."</p>
                <router-link to="/aanbod" class="terug btn">Ga terug</router-link>
            </div>

        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="black" fill-opacity="1"
            d="M0,224L48,224C96,224,192,224,288,202.7C384,181,480,139,576,138.7C672,139,768,181,864,197.3C960,213,1056,203,1152,186.7C1248,171,1344,149,1392,138.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
        </path>
    </svg>
    <div class="container2">
        <div class="row ">
            <div class="info col-md-5">
                <h1>Handelingen</h1>
                <p>Deze massage wordt gegeven door Lin. Bij aankomst wordt je een kopje thee aangeboden, maken we kennis en
                    praten we over wat jouw noden. Daarna kan je je douchen, een goede hygiëne is tenslotte heel belangrijk.
                    Zowel een lekker-geurende douchegel, badhanddoeken als badjas zijn voorzien. Na het verbindingsritueel
                    gaan we over tot de massage. Zowel de gever als de ontvanger zijn hierbij naakt. Na de massage maken we
                    nog even tijd om wat na te praten als daar nood aan is, waarna je indien gewenst terug kan douchen.</p>

            </div>
            <div class="prijzen col-md-6">
                <h1>Prijzen</h1>
                <div class="massage-item">
                    <div class="massage-name">De sensuele tantra massage</div>
                    <div class="massage-duration">90 min</div>
                    <div class="massage-price">200 euro</div>
                </div>
                <div class="massage-item">
                    <div class="massage-name">Lingam massage (altijd incl. sensuele massage)</div>
                    <div class="massage-duration">120 min</div>
                    <div class="massage-price">250 euro</div>
                </div>
            </div>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#ffffff" fill-opacity="1"
            d="M0,224L48,213.3C96,203,192,181,288,149.3C384,117,480,75,576,90.7C672,107,768,181,864,197.3C960,213,1056,171,1152,160C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
        </path>
    </svg>
    <div class="yonim">
        <div class="container2">
            <div class="yoni row ">
                <h1>Lingam Massage</h1>
                <div class="info col-md-5">
                    
                    <h2>Wat is Lingam massage?</h2>
                    <p>Lingam massage is een eeuwenoude praktijk die zich richt op het eren en koesteren van de mannelijke
                        seksualiteit en vitaliteit. Door middel van zachte aanraking en bewuste ademhaling wordt de lingam,
                        ofwel het mannelijke geslachtsorgaan, verzorgd en geëerd. Deze massage kan mannen helpen om spanning
                        los te laten, emotionele blokkades te doorbreken en een dieper bewustzijn van hun seksualiteit te
                        bereiken. Lingam massage

                        wordt uitgevoerd met respect, toestemming en aandacht voor de behoeften en grenzen van de ontvanger.
                        Het kan een diepgaande en transformerende ervaring zijn die mannen in staat stelt om hun seksuele
                        potentieel te verkennen en te omarmen. </p>

                </div>
                <div class="info col-md-5">
                    <h2>Verloop</h2>
                    <p>De lingam massage wordt gegeven door Lin. Tijdens het gesprek in het begin van de sessie zal er
                        besproken worden wat de grenzen zijn van zowel de ontvanger als van de gever.

                        De lingam massage zal altijd vooraf gegaan worden door de sensuele massage waardoor er al volledige
                        ontspanning is van het lichaam en de geest en waarbij er reeds een vertrouwen is tussen de gever en
                        de ontvanger.

                        De lingam massage wordt gegeven met zachte, liefdevolle aanraking en diepe aandacht voor de
                        ontvanger. De gever creëert een veilige en ontspannen sfeer, waarin de ontvanger zich volledig kan
                        overgeven aan de ervaring. Met behulp van warme olie worden verschillende technieken toegepast om de
                        lingam te masseren, met inbegrip van strijken, kneden en druk uitoefenen. Door ademhalingsoefeningen
                        wordt de energie gestimuleerd en verspreid door het hele lichaam, wat kan leiden tot diepe
                        ontspanning en sensuele extase. Na de massage wordt de ontvanger uitgenodigd om rustig terug te
                        keren naar een staat van bewustzijn, terwijl de gever zorgt voor een zachte afronding van de sessie.
                    </p>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'TantraMannenView'
}


</script>

<style scoped>

*, ::after, ::before {
  box-sizing: border-box;
  overflow-x: hidden;
}

.container1 {
    padding-top: 12rem;
    background-image: url('../assets/man 2 banner.png');
    background-size: cover;
}

.info {
    text-align: left;
    padding-left: 12rem;
}

.info p {
    font-size: 1.5rem;
    color: white;
    overflow-y: hidden;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif;
    overflow-x: unset;
    
}

p,
.prijzen div {
    font-family: 'Cormorant', serif;

}


h1 {
    text-align: left;
    font-size: 3rem;
    color: #ecd9a6;

}

.image {
    padding-top: 3rem;

}

.image img {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 70%;
}



.container2 h1 {
    font-size: 4rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: var(--bs-heading-color);

}

.container2 p {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #2c3e50;
}



.prijzen {

    text-align: left;
    padding-left: 12rem;

}

.prijzen div {
    font-size: 1.5rem;
}

.massage-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.massage-name,
.massage-duration,
.massage-price {
    text-align: center;
    overflow-y: hidden;
}

.massage-name {
    text-align: left;
    width: 444px !important;
}

.massage-price {
    text-align: right;
}

.terug {
    font-size: 1.5rem;
    color: black;
    text-decoration: none;
    font-family: 'Cormorant', serif;
    background-color: #CBA135;

}

.terug:hover {
    color: white;
    background-color: black;
}

.yonim {

    background-color: rgb(255, 255, 255);
}

.yoni h1 {
    margin-left: 11rem;
    margin-top: 4rem;
}

@media screen and (max-width: 768px) {
    .info {
        padding-left: 0;
        padding: 2rem;
        
    }
    
        

    .info h1, .prijzen h1, .yonim h1{
        margin: 0;
        padding: 0;
        font-size: 3rem;
    }

    .prijzen {
        padding-left: 0;
        padding: 2rem;
    }

    .yonim h1 {
        margin-left: 1.8rem;
        font-size: 2.5rem;
        
    }

    .prijzen div {
        font-size: 1.2rem;
    }

    .info {
        margin-bottom: 1rem;
    }

    
    
}

</style>